import { Injectable } from '@angular/core'
import { AuthorisationService } from '@services/authorisation.service'
import { Organisation, OrganisationRole, UserDetailsResponse } from '@services/proficloud.interfaces'
import { ProficloudService } from '@services/proficloud.service'
import { BehaviorSubject, Observable, combineLatest, skipWhile } from 'rxjs'
import { first } from 'rxjs/operators'
import { DeviceStore } from '../../device-management/stores/device.store'
import { RBACPolicyRequest } from '../backend/DTO/rbac.dto'
import { MemberManagementHttpService } from '../backend/member-management-http.service'
import { ISessionData } from '../entities/ISessionData'
import { IMemberDevice } from '../entities/device.entity'
import { Invitation } from '../entities/invitation.entity'
import { IMemberError, IMemberErrorID } from '../entities/member-error'
import { Member } from '../entities/member.entity'
import { IRbacMemberPolicy } from '../entities/rbac.entity'

@Injectable({
  providedIn: 'root',
})
export class MemberStore {
  /*
  Don't expose the subject directly to store clients,
  This is to prevent the service clients from themselves emitting store values.
  Only the backend service is allowed to put data into the BSubject.
   */
  private _members$: BehaviorSubject<Member[]> = new BehaviorSubject([])

  private _invitations$: BehaviorSubject<Invitation[]> = new BehaviorSubject([])

  private _devices$: BehaviorSubject<IMemberDevice[]> = new BehaviorSubject([])

  private _rbacPolicies$: BehaviorSubject<IRbacMemberPolicy[]> = new BehaviorSubject([])

  private _session$: BehaviorSubject<ISessionData | null> = new BehaviorSubject<ISessionData | null>(null)

  private _memberRelevantData$: BehaviorSubject<{
    members: Member[]
    invitations: Invitation[]
    rbacPolicies: IRbacMemberPolicy[]
  } | null> = new BehaviorSubject<{ members: Member[]; invitations: Invitation[]; rbacPolicies: IRbacMemberPolicy[] } | null>(null)

  /*
  Subscriptions which need to be mirrored from outer services
   */

  /*
  This exposes the actual data for any store clients.
  Skip next if members array is empty, which is always empty.
   */
  public readonly members$: Observable<Member[]> = this._members$.asObservable().pipe(skipWhile((v) => v.length < 1))

  public readonly invitations$: Observable<Invitation[]> = this._invitations$.asObservable()

  public readonly devices$: Observable<IMemberDevice[]> = this._devices$.asObservable()

  public readonly policies$: Observable<IRbacMemberPolicy[]> = this._rbacPolicies$.asObservable()

  public readonly session$: Observable<ISessionData | null> = this._session$.asObservable()

  public readonly memberRelevantData$: Observable<{
    members: Member[]
    invitations: Invitation[]
    rbacPolicies: IRbacMemberPolicy[]
  } | null> = this._memberRelevantData$.asObservable()

  /*
  Error handling should be in its own file and service. The store should only hold valid data.
  Due to the fact, that I don't want to overload the architecture with files and service I decided to
  implement the error handling in the store as well. Please don't kill me.
   */
  public readonly membersErrorStream$: BehaviorSubject<IMemberError | null> = new BehaviorSubject<IMemberError | null>(null)

  private storeInitialized = false

  /*
  The HTTP Service should always only be injected to stores. Never to other services or components directly.
  This should be the only place to reference the proficloud service.
   */
  constructor(
    private userManagementHTTPService: MemberManagementHttpService,
    private proficloud: ProficloudService,
    private deviceStore: DeviceStore,
    private auth: AuthorisationService
  ) {
    // Loads the member data of the current organization once the user lazy loads the module by clicking the user-management
    // navigation link, or by URL directly
    if (!this.storeInitialized) {
      this.loadSessionData(this.proficloud.currentOrganisation, this.proficloud.keycloakData?.userDetails)
      this.loadMemberData(this.proficloud.currentOrganisation?.organizationId)
    }

    // Loads the member data of the current organization once the organizations are listed in the proficloud service
    this.proficloud.organisationsListed$.subscribe(() => {
      // check if organizationID is present although that state would be an application error if missing.
      this.loadSessionData(this.proficloud.currentOrganisation, this.proficloud.keycloakData?.userDetails)
      this.loadMemberData(this.proficloud.currentOrganisation?.organizationId)
    })

    // Loads the member data of the current organization if the organization was switched
    this.proficloud.organisationSwitched$.subscribe(() => {
      // check if organizationID is present although that state would be an application error if missing.
      this.loadSessionData(this.proficloud.currentOrganisation, this.proficloud.keycloakData?.userDetails)
      this.loadMemberData(this.proficloud.currentOrganisation?.organizationId)
    })

    this.loadDeviceData()
  }

  private loadSessionData = (organisation: Organisation, user?: UserDetailsResponse) => {
    if (organisation && user) {
      const sessionData: ISessionData = {
        userID: user.data.userId,
        admin: this.auth.isAdmin(organisation),
        organisation: organisation,
      }

      this._session$.next(sessionData)
    } else if (this.storeInitialized) {
      const error: IMemberError = {
        id: IMemberErrorID.SESSION_DATA_RETRIEVING_FAILED,
        category: 'SESSION',
        message: 'Could not fetch data. Parameters null or undefined.',
      }
      this.membersErrorStream$.next(error)
    }
  }

  /*
  Loads and maps the backend response. Writes data in the store subject.
  Therefore, the call needs to get:
  1. All members
  2. All invitations
  3. All RBAC policies
   */
  private loadMemberData = (organisationID: string) => {
    if (organisationID) {
      combineLatest([
        this.userManagementHTTPService.getRBACDevicePolicies$(organisationID),
        this.userManagementHTTPService.getMembersByOrganisation$(organisationID),
        this.userManagementHTTPService.getInvitationsByOrganisation$(organisationID),
        this.devices$,
      ]).subscribe({
        next: (observables) => {
          const rbacPolicyDTO = observables[0]
          const memberResponse = observables[1]
          const invitationResponse = observables[2]
          const devices = observables[3]

          const memberDevicePolicies: IRbacMemberPolicy[] = []

          if (rbacPolicyDTO) {
            const policyMap = new Map(Object.entries((rbacPolicyDTO as any).data))

            policyMap.forEach((rbacDevicesEndpoints: string[], memberID) => {
              const memberDevices: IMemberDevice[] = []
              rbacDevicesEndpoints.forEach((endpoint) => {
                const mDev = devices.find((d) => d.endpointId === endpoint)
                if (mDev) {
                  memberDevices.push(mDev)
                }
              })

              const memberPolicies: IRbacMemberPolicy = {
                memberId: memberID,
                devices: memberDevices,
              }
              memberDevicePolicies.push(memberPolicies)
            })

            this._rbacPolicies$.next(memberDevicePolicies)
          } else {
            this._rbacPolicies$.next([])
          }

          const backendUsers: Member[] = memberResponse.data.members.map((member) => {
            const user: Member = {
              userId: member.user.userId,
              created: member.user.created,
              userRole: member.userRole,
              email: member.user.email,
              firstName: member.user.firstName,
              lastName: member.user.lastName,
            }

            return user
          })
          this._members$.next(backendUsers)

          let backendInvitations: Invitation[] = []

          if (invitationResponse.data) {
            backendInvitations = invitationResponse.data.map((invitationInc) => {
              const invitation: Invitation = {
                invitationId: invitationInc.id,
                invitedBy: invitationInc.createdBy,
                created: invitationInc.createdTime,
                invitationLink: invitationInc.invitationLink,
                message: invitationInc.message,
                userId: invitationInc.userId,
                userRole: invitationInc.userRole,
                status: invitationInc.status,
                userEmail: invitationInc.userEmail,
              }

              return invitation
            })
          }

          this._invitations$.next(backendInvitations)

          this.membersErrorStream$.next(null)

          this._memberRelevantData$.next({
            members: backendUsers,
            rbacPolicies: memberDevicePolicies,
            invitations: backendInvitations,
          })
          this.storeInitialized = true
        },
        error: (err) => {
          console.error(err)
        },
      })
    }
  }

  /*
  Loads device data from proficloud service
   */
  private loadDeviceData = () => {
    // Note: I'm not sure if it breaks our pattern to have stores talk to each other but in this case I don't know what else to do.
    // Was better than referencing the device service I suppose.
    this.deviceStore.devices$.subscribe((devices) => {
      if (!devices.length) {
        return
      }

      const memberDevicesList: IMemberDevice[] = []

      devices.forEach((device) => {
        const memberDevice: IMemberDevice = {
          deviceName: device.metadata.deviceName,
          endpointId: device.endpointId,
          uuid: device.metadata.uuid,
        }
        memberDevicesList.push(memberDevice)
      })

      this._devices$.next(memberDevicesList)
    })
  }

  /*
  Updates the role of a member for a specific organization.
  This must be always the current organization, since you can only see members of the current selected org.
   */
  updateMemberRole$(memberId: string, role: OrganisationRole): Observable<any> {
    return new Observable<boolean>((sub) => {
      this.userManagementHTTPService.updateMemberRole$(this.proficloud.currentOrganisation.organizationId, memberId, role).subscribe({
        next: () => {
          this._members$.pipe(first()).subscribe((members) => {
            const member = members.find((m) => m.userId === memberId)
            const newMemberList = members.filter((m) => m.userId !== memberId)
            if (member) {
              member.userRole = role
              newMemberList.push(member)
            }
            this._members$.next(newMemberList)
            sub.next(true)
            sub.complete()
          })
        },
        error: (err) => {
          sub.error(err)
          sub.complete()
        },
      })
    })
  }

  /*
  Updates the role of an invitation. Invitations are assigned to an email, this is why the email is the parameter here.
   */
  updateInvitationRole(invitationId: string, role: OrganisationRole) {
    return new Observable<boolean>((sub) => {
      this.userManagementHTTPService.updateInvitationRole$(this.proficloud.currentOrganisation.organizationId, invitationId, role).subscribe({
        next: () => {
          this._invitations$.pipe(first()).subscribe((invitations) => {
            const invitation = invitations.find((i) => i.invitationId === invitationId)
            const newInvitationList = invitations.filter((i) => i.invitationId !== invitationId)
            if (invitation) {
              invitation.userRole = role
              newInvitationList.push(invitation)
            }
            this._invitations$.next(newInvitationList)
            sub.next(true)
            sub.complete()
          })
        },
        error: (err) => {
          sub.error(err)
          sub.complete()
        },
      })
    })
  }

  inviteMember$(email: string, role: string): Observable<{ emailReceived: boolean; registrationLink: string; userExists: boolean; existingUserId: string }> {
    // There is a default message defined in middleware, and it is therefore not required to let the user send a custom message.
    // It is possible that this feature will be requested again, so the placeholders should stay in place.
    const defaultMessage = ''

    return new Observable<{
      emailReceived: boolean
      registrationLink: string
      userExists: boolean
      existingUserId: string
    }>((sub) => {
      // check if invitation already exists in the array.
      // The backend is not checking this, and we agreed on
      this.invitations$.pipe(first()).subscribe({
        next: (invitations) => {
          const invitation = invitations.find((i) => i.userEmail === email && i.status === 'PENDING')
          if (invitation) {
            const err: IMemberError = {
              id: IMemberErrorID.INVITATION_EXISTS,
              message: '',
              category: 'INVITATIONS',
            }
            this.membersErrorStream$.next(err)
            sub.complete()
          } else {
            this.userManagementHTTPService
              .inviteMemberToOrganisation$(email, defaultMessage, role, this.proficloud.currentOrganisation.organizationId)
              .subscribe({
                next: (invitationResponse) => {
                  const newInvitation: Invitation = {
                    invitationId: invitationResponse.data.invitation.id,
                    invitedBy: invitationResponse.data.invitation.createdBy,
                    created: invitationResponse.data.invitation.createdTime,
                    invitationLink: invitationResponse.data.invitation.invitationLink,
                    message: invitationResponse.data.invitation.message,
                    userId: invitationResponse.data.invitation.userId,
                    userRole: invitationResponse.data.invitation.userRole,
                    status: invitationResponse.data.invitation.status,
                    userEmail: invitationResponse.data.invitation.userEmail,
                  }
                  const newInvitations = invitations.concat([newInvitation])
                  this._invitations$.next(newInvitations)

                  sub.next({
                    emailReceived: invitationResponse.data.emailSent,
                    registrationLink: invitationResponse.data.registrationLink,
                    userExists: invitationResponse.data.existingUser,
                    existingUserId: invitationResponse.data.existingUserId || '',
                  })
                  sub.complete()
                },
                error: (err: IMemberError) => {
                  this.membersErrorStream$.next(err)
                },
              })
          }
        },
      })
    })
  }

  /*
  Description: Removes a member from an organisation
  Parameters: userId: The UUID of the member to be removed
   */
  removeMember(userId: string) {
    return new Observable<boolean>((sub) => {
      this.userManagementHTTPService.removeMemberFromOrganisation$(userId, this.proficloud.currentOrganisation.organizationId).subscribe({
        next: () => {
          this._members$.pipe(first()).subscribe((members) => {
            const purgedMembers: Member[] = members.filter((m) => m.userId !== userId)
            // complete the call with empty next value
            this._members$.next(purgedMembers)
            sub.next(true)

            /*else {
              this.membersErrorStream$.next({
                message: 'No members$ left in organization after removing a member.',
                category: "MEMBERS",
                id: IMemberErrorID.MEMBER_REMOVING_FAILED
              })
              sub.complete()
            }*/
          })
        },
        error: (error: IMemberError) => {
          this.membersErrorStream$.next(error)
          sub.complete()
        },
      })
    })
  }

  /*
    Description: Removes an invitation from an organisation
    Parameters: userId: The UUID of the member to be removed
   */
  removeInvitation(invitationId: string) {
    return new Observable<boolean>((sub) => {
      this.userManagementHTTPService.removeInvitationFromOrganisation$(invitationId, this.proficloud.currentOrganisation.organizationId).subscribe({
        next: () => {
          // update invitations$
          this._invitations$.pipe(first()).subscribe((invitations) => {
            const purgedInvitations = invitations.filter((i) => i.invitationId !== invitationId)
            this._invitations$.next(purgedInvitations)
            sub.next(true)
            sub.complete()
          })
        },
        error: () => {
          const error: IMemberError = {
            id: IMemberErrorID.INVITATION_REMOVING_FAILED,
            message: 'It was not possible to remove the invitation',
            category: 'INVITATIONS',
          }

          this.membersErrorStream$.next(error)
          sub.complete()
        },
      })
    })
  }

  /*
    Description: Get device permissions of all members of the current organization.
  */
  getRBAC() {
    return new Observable<IRbacMemberPolicy[]>((sub) => {
      const error: IMemberError = {
        id: IMemberErrorID.RBAC_RETRIEVING_FAILED,
        message: 'It was not possible to get the device policies.',
        category: 'RBAC_DEVICES',
      }
      this._rbacPolicies$.pipe(first()).subscribe({
        next: (policies) => {
          try {
            sub.next(policies)
            sub.complete()
          } catch (e) {
            this.membersErrorStream$.next(error)
          }
        },
        error: () => {
          this.membersErrorStream$.next(error)
          sub.complete()
        },
      })
    })
  }

  /*
    Description: Assigns permissions for a single device to potentially multiple members.
    Parameters: userIds: The userIds of the members to assign the permissions to.
                assignedDevices: List of lists of deviceIds of the already assigned devices per member.
  */
  assignRBACForMultipleMembers(userIds: string[], assignedDevices: string[][]) {
    return new Observable<IRbacMemberPolicy[]>((sub) => {
      if (userIds && assignedDevices) {
        let rbacRequest: RBACPolicyRequest = {}
        userIds.forEach((userId, index) => {
          Object.defineProperty(rbacRequest, userId, { value: assignedDevices[index], enumerable: true })
        })

        const error: IMemberError = {
          id: IMemberErrorID.RBAC_ASSIGNMENT_FAILED,
          message: 'It was not possible to assign permission to the device',
          category: 'RBAC_DEVICES',
        }

        this.userManagementHTTPService.setRBACDevicePolicies$(this.proficloud.currentOrganisation.organizationId, rbacRequest).subscribe({
          next: () => {
            this._rbacPolicies$.pipe(first()).subscribe({
              next: (policies) => {
                try {
                  sub.next(policies)
                  sub.complete()
                } catch (e) {
                  this.membersErrorStream$.next(error)
                }
              },
            })
          },
          error: () => {
            this.membersErrorStream$.next(error)
            sub.complete()
          },
        })
      }
    })
  }

  /*
    Description: assigns device permissions to a member
    Parameters: userId: The UUID of the member to be removed, assignedDevices: the uuids of devices to be assigned
    Returns: A list of policies containing all member policies. Reason: The origin component decides weather to update the store or not
 */
  assignRBAC(uuid: string, assignedDevices: string[]) {
    return new Observable<IRbacMemberPolicy[]>((sub) => {
      if (uuid && assignedDevices) {
        const rbacRequest: RBACPolicyRequest = {
          [uuid]: assignedDevices,
        }

        const error: IMemberError = {
          id: IMemberErrorID.RBAC_ASSIGNMENT_FAILED,
          message: 'It was not possible to assign the devices',
          category: 'RBAC_DEVICES',
        }

        this.userManagementHTTPService.setRBACDevicePolicies$(this.proficloud.currentOrganisation.organizationId, rbacRequest).subscribe({
          next: () => {
            // update member device permissions
            combineLatest([this._devices$, this._rbacPolicies$])
              .pipe(first())
              .subscribe({
                next: (values) => {
                  const devices = values[0]
                  const policies = values[1]

                  // member can be undefined if there are no policies for them
                  let member = policies.find((p) => p.memberId === uuid)
                  const purgedPolicies = policies.filter((p) => p.memberId !== uuid)

                  const memberAssignedDevices: IMemberDevice[] = []

                  try {
                    assignedDevices.forEach((aEndpoint) => {
                      const device = devices.find((d) => d.endpointId === aEndpoint)
                      if (device) {
                        memberAssignedDevices.push(device)
                      }
                    })

                    if (member) {
                      member.devices = memberAssignedDevices
                    } else {
                      member = {
                        memberId: uuid,
                        devices: memberAssignedDevices,
                      }
                    }

                    sub.next(purgedPolicies.concat([member]))
                    sub.complete()
                  } catch (e) {
                    this.membersErrorStream$.next(error)
                  }
                },
              })
          },
          error: () => {
            this.membersErrorStream$.next(error)
            sub.error(error)
            sub.complete()
          },
        })
      }
    })
  }

  updatePolicies(policies: IRbacMemberPolicy[]) {
    this._rbacPolicies$.next(policies)
  }
}
