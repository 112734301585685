import { HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { EmmaHttpService } from '@emma/backend/emma-http.service'
import {
  AcceptableMeasurementTypes,
  AggregatedMetric,
  AggregationMethods,
  AlertRule,
  BaseUnitID,
  BaseUnitTypeID,
  BaseUnitWithInfo,
  Calculation,
  ChangeEvent,
  ChartType,
  ComparisonType,
  CompositeUnitType,
  Cost,
  CostsTypes,
  CreateDeviceMapping,
  Dashboard,
  DashboardReport,
  EMSStoreErrorState,
  EmmaAlertMessage,
  EmmaAlertMessagesOptions,
  FeatureSetAndLimitsResponse,
  FormulaNodeTypes,
  MeteringPoint,
  Metric,
  QueryableTypedID,
  Rate,
  RecurringReport,
  ReplaceDeviceMapping,
  ReportGeneration,
  ReportGenerationStatus,
  TypedQueryableID,
  Unit,
  UnitPrefix,
  UnitTypeDefaults,
  WageTypeInfo,
  Widget,
  WindowPeriods,
} from '@emma/type-definitions/types'
import { BillingService } from '@services/billing/billing.service'
import { DMSDeviceMapping } from '@services/proficloud.interfaces'
import { ProficloudService } from '@services/proficloud.service'
import { BehaviorSubject, Observable, Subject, Subscription, forkJoin, tap } from 'rxjs'
import { BillingStore } from '../../../stores/billing.store'

@Injectable({
  providedIn: 'root',
})
export class EmmaStore {
  // Internal error flag
  private errored = false

  // Note: This is for externally flagging that an error has occurred on a data fetch
  // This is necessary because it could happen at any time and we need to know in components when we should not clear error status messages
  public dataFetchError = false

  // Private subjects
  private _dashboards$: BehaviorSubject<Dashboard[] | false> = new BehaviorSubject(false)

  private _allTypesAndUnits$: BehaviorSubject<{
    baseUnitTypeDefaults: Map<BaseUnitTypeID, UnitTypeDefaults>
    wageTypeInfo: Map<Exclude<AcceptableMeasurementTypes, null>, WageTypeInfo>
    baseTypes: BaseUnitTypeID[]
    compositeTypes: CompositeUnitType[]
    baseUnits: BaseUnitWithInfo[]
  }> = new BehaviorSubject({
    baseUnitTypeDefaults: new Map(),
    wageTypeInfo: new Map(),
    baseTypes: [],
    compositeTypes: [],
    baseUnits: [],
  })

  private _queryableDataSources$: BehaviorSubject<{
    tree: MeteringPoint[]
    inbox: Metric[]
    sums: Calculation[]
    avgs: Calculation[]
    kpis: Calculation[]
    mappings: DMSDeviceMapping[]
  }> = new BehaviorSubject({
    tree: [],
    inbox: [],
    sums: [],
    avgs: [],
    kpis: [],
    mappings: [],
  })

  private _alertMessagesAndRules$: BehaviorSubject<{
    rules: AlertRule[]
    messages: EmmaAlertMessage[]
  }> = new BehaviorSubject({
    rules: [],
    messages: [],
  })

  private _reports$: BehaviorSubject<{
    manualReports: DashboardReport[]
    recurringReports: RecurringReport[]
  }> = new BehaviorSubject({
    recurringReports: [],
    manualReports: [],
  })

  private _rawDataChanged$: BehaviorSubject<{
    metridId: string
  }> = new BehaviorSubject({ metridId: '' })

  private _alertRules$: BehaviorSubject<AlertRule[]> = new BehaviorSubject([])

  private _alertMessages$: BehaviorSubject<EmmaAlertMessage | EmmaAlertMessage[]> = new BehaviorSubject([])

  private _alertMessageStreamSubscription: Subscription

  private _reportCreationStreamSubscription: Subscription

  private _reportStatus$: BehaviorSubject<ReportGenerationStatus | false> = new BehaviorSubject(false)

  private _costs$: BehaviorSubject<Cost[]> = new BehaviorSubject([])

  private _limits$: BehaviorSubject<FeatureSetAndLimitsResponse> = new BehaviorSubject({
    features: [],
    limits: {},
    packageType: 'NONE',
  })

  // We cannot call error() on our data streams because this causes them to close.
  // Instead we have a dedicated error stream which gives an internal status of the whole store.
  private _errorState$: Subject<EMSStoreErrorState> = new Subject()

  // Public subjects
  public readonly dashboards$: Observable<Dashboard[] | false> = this._dashboards$.asObservable()

  public readonly queryableDataSources$: Observable<{
    tree: MeteringPoint[]
    inbox: Metric[]
    sums: Calculation[]
    avgs: Calculation[]
    kpis: Calculation[]
    mappings: DMSDeviceMapping[]
  }> = this._queryableDataSources$.asObservable()

  public readonly allTypesAndUnits$: Observable<{
    baseUnitTypeDefaults: Map<BaseUnitTypeID, UnitTypeDefaults>
    wageTypeInfo: Map<Exclude<AcceptableMeasurementTypes, null>, WageTypeInfo>
    baseTypes: BaseUnitTypeID[]
    compositeTypes: CompositeUnitType[]
    baseUnits: BaseUnitWithInfo[]
  }> = this._allTypesAndUnits$.asObservable()

  public readonly alertMessagesAndRules$: Observable<{
    rules: AlertRule[]
    messages: EmmaAlertMessage[]
  }> = this._alertMessagesAndRules$.asObservable()

  public readonly reports$: Observable<{
    manualReports: DashboardReport[]
    recurringReports: RecurringReport[]
  }> = this._reports$.asObservable()

  public readonly alertRules$: Observable<AlertRule[]> = this._alertRules$.asObservable()

  public readonly alertMessages$: Observable<EmmaAlertMessage | EmmaAlertMessage[]> = this._alertMessages$.asObservable()

  public readonly costs$: Observable<Cost[]> = this._costs$.asObservable()

  public readonly limits$: Observable<FeatureSetAndLimitsResponse> = this._limits$.asObservable()

  public readonly rawDataChanged$ = this._rawDataChanged$.asObservable()

  public readonly reportStatus$ = this._reportStatus$.asObservable()

  public readonly errorState$ = this._errorState$.asObservable()

  // Note: This is different to the MemberStore pattern, as I need a way for components to know if the store has been completely initialized
  // Feels like a break of the pattern but otherwise don't know how to deal with a component that needs to wait for multiple resources to have all been loaded
  public storeInitialised$ = new BehaviorSubject<boolean | undefined>(undefined)
  private dashboardsInitialised = false
  private queryableDataSourcesInitialised = false
  private allTypesAndUnitsInitialised = false
  private costsInitialised = false
  private alertsInitialised = false
  private limitsInitialised = false

  private organisationSwitchBegunSubscription: Subscription
  private organisationsListedSubscription: Subscription
  private organisationSwitchedSubscription: Subscription
  private emmaSubscriptionsListedSubscription: Subscription

  constructor(
    private billing: BillingService, // TODO: I don't like this being here
    private billingStore: BillingStore,
    private emmaHttp: EmmaHttpService,
    private proficloud: ProficloudService
  ) {
    // Subscribe to relevant observables
    this.organisationSwitchBegunSubscription = this.proficloud.organisationSwitchBegun$.subscribe(() => {
      this.reset()
    })

    this.organisationsListedSubscription = this.proficloud.organisationsListed$.subscribe(() => {
      // TODO: Anything?
    })

    // Note: The reason we don't do anything here is because that EMMA subscriptions always get loaded after the organisation is switched
    // Doesn't feel good, but we always have to wait for the subscriptions before anything else can happen.
    // Perhaps this could be solved by pulling the subscriptions into the store, even though it's a billing service?
    this.organisationSwitchedSubscription = this.proficloud.organisationSwitched$.subscribe(() => {
      // TODO: Anything?
    })

    this.emmaSubscriptionsListedSubscription = this.billingStore.allSubscriptions$.subscribe({
      next: (res) => {
        // First one down will be an empty object so skip that
        if (typeof res.emsSubscriptions !== 'undefined') {
          this.reload()
        }
      },
      // Note: Error should never happen, we use the error observable instead
    })

    this.billingStore.errorState$.subscribe((state) => {
      if (state.subscriptions) {
        // Note: An error in subscriptions pretty much always means that the switched organisation does not have any subscriptions.
        // In this case we do a reload anyway, because the components need to go through the init process and will not know to complete themselves.
        this.reload()
      }
    })
  }

  private reload() {
    this.reset()

    // Dashboards stuff
    this.refreshQueryableDataSources()
    this.loadAllUnitsAndMeasurementTypes()
    this.loadDashboardsData()
    this.getCosts()
    this.getLimits()

    // Alerting
    this.loadAlertMessagesAndRules()
    this.setupEmmaAlerts()
  }

  private reset() {
    this.errored = false
    this.dataFetchError = false
    this.dashboardsInitialised = false
    this.queryableDataSourcesInitialised = false
    this.allTypesAndUnitsInitialised = false
    this.costsInitialised = false
    this.alertsInitialised = false
  }

  private emitStoreInitialised() {
    if (
      this.dashboardsInitialised &&
      this.queryableDataSourcesInitialised &&
      this.allTypesAndUnitsInitialised &&
      this.costsInitialised &&
      this.alertsInitialised
    ) {
      this.storeInitialised$.next(this.errored)
    }
  }

  public refreshDashboards() {
    this.getDashboards().subscribe({
      next: (dashboards) => {
        this._dashboards$.next(dashboards)
      },
      error: (err: HttpErrorResponse) => {
        this.errored = true // Might not be necessary

        // Error state
        this._errorState$.next({ dashboards: err })
      },
    })
  }

  private loadDashboardsData() {
    this.getDashboards().subscribe({
      next: (dashboards) => {
        this._dashboards$.next(dashboards)
        this.dashboardsInitialised = true
        this.emitStoreInitialised()
      },
      error: (err: HttpErrorResponse) => {
        this.errored = true
        this.emitStoreInitialised()

        // Error state
        this._errorState$.next({ dashboards: err })
      },
    })
  }

  private refreshQueryableDataSources() {
    forkJoin([
      this.emmaHttp.getMeteringPointTree(),
      this.emmaHttp.getUnassignedMetrics(),
      this.emmaHttp.getCalculations(),
      this.emmaHttp.getDeviceMappings(),
    ]).subscribe({
      next: (res) => {
        this._queryableDataSources$.next({
          tree: res[0],
          inbox: res[1],
          sums: res[2].filter((c) => c.calculationType === 'SUM'),
          avgs: res[2].filter((c) => c.calculationType === 'AVG'),
          kpis: res[2].filter((c) => c.calculationType === 'FORMULA'),
          mappings: res[3].data,
        })
        this.queryableDataSourcesInitialised = true
        this.emitStoreInitialised()
      },
      error: (err: HttpErrorResponse) => {
        this.errored = true
        this.queryableDataSourcesInitialised = true
        this.emitStoreInitialised()

        // Error state
        this._errorState$.next({ queryables: err })
      },
    })
  }

  public refreshDashboardReports(dashboardId: string) {
    forkJoin([this.emmaHttp.getDashboardRecurringReports(dashboardId), this.emmaHttp.getDashboardReports(dashboardId)]).subscribe({
      next: (res) => {
        this._reports$.next({
          recurringReports: res[0],
          manualReports: res[1],
        })
      },
      error: (err: HttpErrorResponse) => {
        // Error state
        this._errorState$.next({ reports: err })
      },
    })
  }

  // Subscriptions
  // TODO: Perhaps we move this into a new general subscriptions store, let's see
  public getFeatureSetAndLimits() {
    return this.emmaHttp.getFeatureSetAndLimits()
  }

  // Dashboard and Widgets
  public getDashboards() {
    return this.emmaHttp.getDashboards()
  }

  public createDashboard(dashboard: Dashboard) {
    return this.emmaHttp.createDashboard(dashboard)
  }

  public updateDashboard(id: string, params: Partial<Dashboard>) {
    return this.emmaHttp.updateDashboard(id, params)
  }

  public deleteDashboard(id: string) {
    return this.emmaHttp.deleteDashboard(id)
  }

  public createWidget(dashboardId: string, widget: Partial<Widget<ComparisonType, ChartType>>) {
    return this.emmaHttp.createWidget(dashboardId, widget)
  }

  public updateWidget(dashboardId: string, widget: Partial<Widget<ComparisonType, ChartType>>) {
    return this.emmaHttp.updateWidget(dashboardId, widget)
  }

  public deleteWidget(dashboardId: string, widgetId: string) {
    return this.emmaHttp.deleteWidget(dashboardId, widgetId)
  }

  public renderWidgetPlot(dashboardId: string, widgetId: string) {
    return this.emmaHttp.renderWidgetPlot(dashboardId, widgetId)
  }

  public renderDashboardPlots(dashboardId: string) {
    return this.emmaHttp.renderDashboardPlots(dashboardId)
  }

  public getDashboardReportData(dashboardId: string, reportId: string) {
    return this.emmaHttp.getDashboardReportData(dashboardId, reportId)
  }

  public createDashboardRecurringReport(dashboardId: string, report: Partial<RecurringReport>) {
    return this.wrapSuccessWithReportsRefresh(dashboardId, this.emmaHttp.createDashboardRecurringReport(dashboardId, report))
  }

  public editDashboardRecurringReport(dashboardId: string, report: Partial<RecurringReport>) {
    return this.wrapSuccessWithReportsRefresh(dashboardId, this.emmaHttp.editDashboardRecurringReport(dashboardId, report))
  }

  public createDashboardManualReport(dashboardId: string, report: Partial<ReportGeneration>) {
    return this.wrapSuccessWithReportsRefresh(dashboardId, this.emmaHttp.createDashboardManualReport(dashboardId, report))
  }

  public deleteDashboardRecurringReport(dashboardId: string, reportId: string) {
    return this.wrapSuccessWithReportsRefresh(dashboardId, this.emmaHttp.deleteDashboardRecurringReport(dashboardId, reportId))
  }

  public deleteDashboardManualReport(dashboardId: string, reportId: string) {
    return this.wrapSuccessWithReportsRefresh(dashboardId, this.emmaHttp.deleteDashboardManualReport(dashboardId, reportId))
  }

  public pauseDashboardRecurringReport(dashboardId: string, reportId: string) {
    return this.emmaHttp.pauseDashboardRecurringReport(dashboardId, reportId)
  }

  public resumeDashboardRecurringReport(dashboardId: string, reportId: string) {
    return this.emmaHttp.resumeDashboardRecurringReport(dashboardId, reportId)
  }

  private getLimits() {
    this.getFeatureSetAndLimits().subscribe({
      next: (res) => {
        this._limits$.next(res)
        this.limitsInitialised
        this.emitStoreInitialised()
      },
      error: (err: HttpErrorResponse) => {
        // If we have no billing account then we don't want to error the stream as it will close, instead send down a 'NONE'.
        if (err.status === 402) {
          this._limits$.next({
            features: [],
            limits: {},
            packageType: 'NONE',
          })
        } else {
          // Error state
          this._errorState$.next({ limits: err })
        }
        this.emitStoreInitialised()
      },
    })
  }

  private getCosts() {
    return this.emmaHttp.getCosts().subscribe({
      next: (res) => {
        this._costs$.next(res)
        this.costsInitialised = true
        this.emitStoreInitialised()
      },
      error: (err: HttpErrorResponse) => {
        this.emitStoreInitialised()

        // Error state
        this._errorState$.next({ costs: err })
      },
    })
  }

  private loadAllUnitsAndMeasurementTypes() {
    forkJoin([this.emmaHttp.getBaseUnitDefaults(), this.emmaHttp.getWageTypeInfo(), this.emmaHttp.getUnitTypes(), this.emmaHttp.getBaseUnits()]).subscribe({
      next: (res) => {
        this._allTypesAndUnits$.next({
          baseUnitTypeDefaults: res[0],
          wageTypeInfo: res[1],
          baseTypes: res[2].baseTypes,
          compositeTypes: res[2].compositeTypes,
          baseUnits: res[3],
        })
        this.allTypesAndUnitsInitialised = true
        this.emitStoreInitialised()
      },
      error: (err) => {
        this.errored = true
        this.emitStoreInitialised()

        // Error state
        this._errorState$.next({ typesAndUnits: err })
      },
    })
  }

  public loadAlertMessagesAndRules() {
    forkJoin([this.emmaHttp.getAlertRules(), this.emmaHttp.getEmmaAlertMessages()]).subscribe({
      next: (res) => {
        this._alertMessagesAndRules$.next({
          rules: res[0].data,
          messages: res[1].data,
        })

        this.alertsInitialised = true
        this.emitStoreInitialised()
      },
      error: (err) => {
        this.errored = true
        this.emitStoreInitialised()

        // Error state
        this._errorState$.next({ alertMessagesAndRules: err })
      },
    })
  }

  public getEmmaAlertRules() {
    return this.emmaHttp.getAlertRules().subscribe({
      next: (res) => {
        this._alertRules$.next(res.data)
        this.alertsInitialised = true
        this.emitStoreInitialised()
      },
      error: (err: HttpErrorResponse) => {
        this.errored = true
        this.alertsInitialised = true
        this.emitStoreInitialised()

        // Error state
        this._errorState$.next({ alertMessagesAndRules: err })
      },
    })
  }

  public getEmmaAlertMessages(options: EmmaAlertMessagesOptions = { status: 'all', page: 1, limit: 25 }) {
    return this.emmaHttp.getEmmaAlertMessages(options).subscribe({
      next: (res) => {
        this._alertMessages$.next(res.data)
      },
      error: (err: HttpErrorResponse) => {
        // TODO: How to handle error here
      },
    })
  }

  public getAlertRuleMessages(parentID: string, options: EmmaAlertMessagesOptions = { status: 'all', page: 1, limit: 25 }) {
    return this.emmaHttp.getAlertRuleMessages(parentID, options).subscribe({
      next: (res) => {
        this._alertMessages$.next(res.data)
      },
      error: (err: HttpErrorResponse) => {
        // TODO: How to handle error here
      },
    })
  }

  public getEmmaAlertMessage(id: string) {
    return this.emmaHttp.getEmmaAlertMessage(id)
  }

  public deleteOrphanAlertMessages() {
    return this.wrapSuccessWithAlertRulesRefresh(this.emmaHttp.deleteOrphanAlertMessages())
  }

  // SSE Streams

  public setupEmmaAlerts() {
    // Do nothing if we don't have any EMMA subscriptions, this prevents a stream being opened which then gets cut off after 45 seconds due to inactivity
    if (!this.billing.emsSubscriptions?.length) {
      return
    }
    // Set up SSE stream for alerts
    this._alertMessageStreamSubscription = this.emmaHttp.observeEmmaAlerts().subscribe({
      next: (alert: EmmaAlertMessage) => {
        if (alert?.metricId) {
          // Default expansion state (maybe move to service)
          alert.expansionState = 'collapsed'
          alert.responsible = 'emma'
          this._alertMessages$.next(alert)
        }
      },
      error: (err) => {
        console.log('Alerts stream error', err)
      },
    })
  }

  public setupReportCreationObservation(dashboardId: string, taskId: string) {
    // Set up SSE stream for alerts
    this._reportCreationStreamSubscription = this.emmaHttp.observeReportCreation(dashboardId, taskId).subscribe({
      next: (status: ReportGenerationStatus) => {
        if (status.status === 'FAILED') {
          this.emmaHttp.stopReportCreationObservation()
          this._reportStatus$.next(status)
          this.refreshDashboardReports(dashboardId) // maybe not
        }
        if (status.status === 'FINISHED') {
          this.emmaHttp.stopReportCreationObservation()
          this._reportStatus$.next(status)
          this.refreshDashboardReports(dashboardId)
        }
      },
      error: (err) => {
        console.log('Report creation stream error', err)
      },
    })
  }

  // Wrappers for POST/PUT/PATCH/DELETE requests
  public wrapSuccessWithDashboardsRefresh<T>(apiCall$: Observable<T>) {
    return new Observable<T>((res) => {
      apiCall$.subscribe({
        next: (response) => {
          this.loadDashboardsData()
          res.next(response)
          res.complete()
        },
        error: (err) => {
          res.error(err)
        },
      })
    })
  }

  private wrapSuccessWithQueryableRefresh<T>(apiCall$: Observable<T>) {
    return new Observable<T>((res) => {
      apiCall$.subscribe({
        next: (response) => {
          this.refreshQueryableDataSources()
          res.next(response)
          res.complete()
        },
        error: (err) => {
          res.error(err)
        },
      })
    })
  }

  private wrapSuccessAndErrorWithQueryableRefresh<T>(apiCall$: Observable<T>) {
    return new Observable<T>((res) => {
      apiCall$.subscribe({
        next: (response) => {
          this.refreshQueryableDataSources()
          res.next(response)
          res.complete()
        },
        error: (err) => {
          this.refreshQueryableDataSources()
          res.error(err)
        },
      })
    })
  }

  private wrapSuccessWithUnitsRefresh<T>(apiCall$: Observable<T>) {
    return new Observable<T>((res) => {
      apiCall$.subscribe({
        next: (response) => {
          this.loadAllUnitsAndMeasurementTypes()
          res.next(response)
          res.complete()
        },
        error: (err) => {
          res.error(err)
        },
      })
    })
  }

  private wrapSuccessWithUnitsAndQueryablesRefresh<T>(apiCall$: Observable<T>) {
    return new Observable<T>((res) => {
      apiCall$.subscribe({
        next: (response) => {
          this.loadAllUnitsAndMeasurementTypes()
          this.refreshQueryableDataSources()
          res.next(response)
          res.complete()
        },
        error: (err) => {
          res.error(err)
        },
      })
    })
  }

  private wrapSuccessWithAlertRulesRefresh<T>(apiCall$: Observable<T>) {
    return new Observable<T>((res) => {
      apiCall$.subscribe({
        next: (response) => {
          this.getEmmaAlertRules()
          res.next(response)
          res.complete()
        },
        error: (err) => {
          res.error(err)
        },
      })
    })
  }

  private wrapSuccessWithReportsRefresh<T>(dashboardId: string, apiCall$: Observable<T>) {
    return new Observable<T>((res) => {
      apiCall$.subscribe({
        next: (response) => {
          this.refreshDashboardReports(dashboardId)
          res.next(response)
          res.complete()
        },
        error: (err) => {
          res.error(err)
        },
      })
    })
  }

  private wrapWithDataFetchErrorFlag<T>(apiCall$: Observable<T>) {
    return new Observable<T>((res) => {
      apiCall$.subscribe({
        next: (response) => {
          res.next(response)
          res.complete()
        },
        error: (err) => {
          this.dataFetchError = true
          res.error(err)
        },
      })
    })
  }

  public createMeteringPoint(toCreate: MeteringPoint) {
    return this.emmaHttp.createMeteringPoint(toCreate)
  }

  public updateMeteringPoint(id: string, params: Partial<MeteringPoint>) {
    return this.emmaHttp.updateMeteringPoint(id, params)
  }

  public updateMeteringPointWithRefresh(id: string, params: Partial<MeteringPoint>) {
    return this.wrapSuccessWithQueryableRefresh(this.emmaHttp.updateMeteringPoint(id, params))
  }

  public deleteMeteringPoint(id: string) {
    return this.wrapSuccessWithQueryableRefresh(this.emmaHttp.deleteMeteringPoint(id))
  }

  public deleteMetric(id: string) {
    return this.wrapSuccessWithQueryableRefresh(this.emmaHttp.deleteMetric(id))
  }

  public editMetric(id: string, params: Partial<Metric>) {
    return this.wrapSuccessWithUnitsRefresh(this.wrapSuccessWithQueryableRefresh(this.emmaHttp.editMetric(id, params)))
  }

  public assignMetricToMeteringPoint(metricId: string, meteringPointId: string | null) {
    return this.wrapSuccessAndErrorWithQueryableRefresh(this.emmaHttp.assignMetricToMeteringPoint(metricId, meteringPointId))
  }

  public editAggregatedMetric(id: string, params: Partial<AggregatedMetric>) {
    return this.emmaHttp.editAggregatedMetric(id, params)
  }

  public deleteCalculation(id: string) {
    return this.wrapSuccessWithUnitsAndQueryablesRefresh(this.emmaHttp.deleteCalculation(id))
  }

  public updateCalculation(id: string, params: { appearance: { colour?: string } }) {
    return this.wrapSuccessWithUnitsAndQueryablesRefresh(this.emmaHttp.updateCalculation(id, params))
  }

  public createSumCalculation(params: { name: string; metricIds: string[]; meteringPointIds: TypedQueryableID[] }) {
    return this.wrapSuccessWithUnitsAndQueryablesRefresh(this.emmaHttp.createSumCalculation(params))
  }

  public editSumCalculation(id: string, params: { name: string; metricIds: string[]; meteringPointIds: TypedQueryableID[] }) {
    return this.wrapSuccessWithUnitsAndQueryablesRefresh(this.emmaHttp.editSumCalculation(id, params))
  }

  public createAverageCalculation(params: { name: string; metricIds: string[]; meteringPointIds: TypedQueryableID[] }) {
    return this.wrapSuccessWithUnitsAndQueryablesRefresh(this.emmaHttp.createAverageCalculation(params))
  }

  public editAverageCalculation(id: string, params: { name: string; metricIds: string[]; meteringPointIds: TypedQueryableID[] }) {
    return this.wrapSuccessWithUnitsAndQueryablesRefresh(this.emmaHttp.editAverageCalculation(id, params))
  }

  public createRate(costId: string, newRate: Rate) {
    return this.emmaHttp.createRate(costId, newRate)
  }

  public updateRate(costId: string, newRate: Rate) {
    return this.emmaHttp.updateRate(costId, newRate)
  }

  public deleteRate(costId: string, newRate: Rate) {
    return this.emmaHttp.deleteRate(costId, newRate)
  }

  public updateCost(costId: string, price: number) {
    return this.emmaHttp.updateCost(costId, price)
  }

  public updateCostUnit(costId: string, unit: Unit, type: 'cost' | 'consumption') {
    return this.emmaHttp.updateCostUnit(costId, unit, type)
  }

  public createFormulaCalculation(
    name: string,
    nodes: {
      type: string
      value: string | { prefix: UnitPrefix; baseUnit: string } | { id: string; unitType: BaseUnitTypeID; measurementType: AcceptableMeasurementTypes }
    }[]
  ) {
    return this.wrapSuccessWithUnitsAndQueryablesRefresh(this.emmaHttp.createFormulaCalculation(name, nodes))
  }

  public updateFormulaCalculation(
    id: string,
    name: string,
    nodes: {
      type: string
      value: string | { prefix: UnitPrefix; baseUnit: string } | { id: string; unitType: BaseUnitTypeID; measurementType: AcceptableMeasurementTypes }
    }[]
  ) {
    return this.wrapSuccessWithUnitsAndQueryablesRefresh(this.emmaHttp.updateFormulaCalculation(id, name, nodes))
  }

  public validateFormula(nodes: { type: FormulaNodeTypes; value: any }[]) {
    return this.emmaHttp.validateFormula(nodes)
  }

  public uploadCSVMetrics(filesData: Record<string, string>[], meteringPointId?: string) {
    return this.wrapSuccessWithQueryableRefresh(this.emmaHttp.uploadCSVMetrics(filesData, meteringPointId))
  }

  public acceptCSVOverwrite(taskId: string) {
    return this.emmaHttp.acceptCSVOverwrite(taskId)
  }

  public getAvailbleDMSDevices() {
    return this.emmaHttp.getAvailbleDMSDevices()
  }

  public createDMSDeviceMapping(mappings: CreateDeviceMapping[]) {
    return this.wrapSuccessWithUnitsRefresh(this.wrapSuccessWithQueryableRefresh(this.emmaHttp.createDMSDeviceMapping(mappings)))
  }

  public replaceDMSDeviceMapping(mapping: ReplaceDeviceMapping) {
    return this.wrapSuccessWithUnitsRefresh(this.wrapSuccessWithQueryableRefresh(this.emmaHttp.replaceDMSDeviceMapping(mapping)))
  }

  public createAlertRule(newAlertRule: AlertRule) {
    return this.wrapSuccessWithAlertRulesRefresh(this.emmaHttp.createAlertRule(newAlertRule))
  }

  public updateAlertRule(id: string, params: Partial<AlertRule>) {
    return this.emmaHttp.updateAlertRule(id, params)
  }

  public deleteAlertRule(alertRuleID: string) {
    return this.wrapSuccessWithAlertRulesRefresh(this.emmaHttp.deleteAlertRule(alertRuleID))
  }

  // Query data methods
  public queryWidgetData(
    startDates: Date[],
    endDates: Date[],
    queryables: QueryableTypedID[],
    groupBy: WindowPeriods,
    options?: {
      unit?: BaseUnitID
      unitType?: BaseUnitTypeID | 'kpi'
      prefix?: UnitPrefix
      aggregation?: AggregationMethods
      costType?: CostsTypes | null
    }
  ) {
    return this.wrapWithDataFetchErrorFlag(this.emmaHttp.queryWidgetData(startDates, endDates, queryables, groupBy, options))
  }

  // Query data methods
  public queryWidgetForecastData(
    startDates: Date[],
    endDates: Date[],
    queryables: QueryableTypedID[],
    groupBy: WindowPeriods,
    options?: {
      unit?: BaseUnitID
      unitType?: BaseUnitTypeID | 'kpi'
      prefix?: UnitPrefix
      aggregation?: AggregationMethods
      costType?: CostsTypes | null
    }
  ) {
    return this.wrapWithDataFetchErrorFlag(this.emmaHttp.queryWidgetForecastData(startDates, endDates, queryables, groupBy, options))
  }

  public checkForecastModels(queryables: QueryableTypedID[]) {
    return this.wrapWithDataFetchErrorFlag(this.emmaHttp.checkForecastModels(queryables))
  }

  // Query data methods
  public trainForecastModel(queryable: QueryableTypedID) {
    return this.wrapWithDataFetchErrorFlag(this.emmaHttp.trainForecastModel(queryable))
  }

  public queryWidgetMetaData(startDates: Date[], endDates: Date[], queryables: QueryableTypedID[], costType?: CostsTypes) {
    return this.wrapWithDataFetchErrorFlag(this.emmaHttp.queryWidgetMetaData(startDates, endDates, queryables, { costType }))
  }

  public queryWidgetDataAggregated(
    startDates: Date[],
    endDates: Date[],
    queryables: QueryableTypedID[],
    groupBy?: WindowPeriods,
    options?: {
      unit?: BaseUnitID
      unitType?: BaseUnitTypeID | 'kpi'
      prefix?: UnitPrefix
      aggregation?: AggregationMethods
      costType?: CostsTypes | null
    }
  ) {
    return this.wrapWithDataFetchErrorFlag(this.emmaHttp.queryWidgetDataAggregated(startDates, endDates, queryables, groupBy, options))
  }

  public queryWidgetDataCSV(
    startDates: Date[],
    endDates: Date[],
    decimalSeparator: string,
    columnSeparator: string,
    queryables: QueryableTypedID[],
    groupBy: WindowPeriods,
    unitType?: BaseUnitTypeID | 'kpi',
    costType?: CostsTypes
  ) {
    return this.wrapWithDataFetchErrorFlag(
      this.emmaHttp.queryWidgetDataCSV(startDates, endDates, decimalSeparator, columnSeparator, queryables, groupBy, { unitType, costType })
    )
  }

  public querySummedData(startDates: Date[], endDates: Date[], queryables: QueryableTypedID[], groupBy: WindowPeriods) {
    return this.wrapWithDataFetchErrorFlag(this.emmaHttp.querySummedData(startDates, endDates, queryables, groupBy))
  }

  public queryWidgetSankeyData(
    startDates: Date[],
    endDates: Date[],
    queryables: QueryableTypedID[],
    groupBy: WindowPeriods,
    options?: {
      unit?: BaseUnitID
      unitType?: BaseUnitTypeID | 'kpi'
      prefix?: UnitPrefix
      aggregation?: AggregationMethods
      costType?: CostsTypes | null
    }
  ) {
    return this.wrapWithDataFetchErrorFlag(this.emmaHttp.queryWidgetSankeyData(startDates, endDates, queryables, groupBy, options))
  }

  public requestDateEmailExport(
    emails: string[],
    decimalSeparator: string,
    columnSeparator: string,
    startDates: Date[],
    endDates: Date[],
    queryableIds: QueryableTypedID[],
    groupBy: WindowPeriods,
    unitType?: BaseUnitTypeID | 'kpi',
    costType?: CostsTypes
  ) {
    return this.emmaHttp.requestDateEmailExport(emails, decimalSeparator, columnSeparator, startDates, endDates, queryableIds, groupBy, {
      unitType,
      costType,
    })
  }

  public queryWidgetStatistics(
    startDates: Date[],
    endDates: Date[],
    queryables: QueryableTypedID[],
    groupBy: WindowPeriods,
    unitType?: BaseUnitTypeID | 'kpi',
    options?: {
      unit?: BaseUnitID
      unitType?: BaseUnitTypeID
      prefix?: UnitPrefix
      costType?: CostsTypes
    }
  ) {
    return this.wrapWithDataFetchErrorFlag(this.emmaHttp.queryWidgetStatistics(startDates, endDates, queryables, groupBy, unitType, options))
  }

  public queryRawData(metricIds: string[], startDate: Date, endDate: Date, options?: { page?: number; limit?: number; includeEmpty?: boolean }) {
    return this.wrapWithDataFetchErrorFlag(this.emmaHttp.queryRawData(metricIds, startDate, endDate, options))
  }

  public getChangeLogs(metricIds: string[], startDate: Date, endDate: Date, options?: { page?: number; limit?: number }) {
    return this.wrapWithDataFetchErrorFlag(this.emmaHttp.getChangeLogs(metricIds, startDate, endDate, options))
  }

  public updateMetricValue(id: string, timestamp: string, fromValue: string | null, toValue: string | null) {
    return this.emmaHttp.updateMetricValue(id, timestamp, fromValue, toValue).pipe(
      tap((resp) => {
        this._rawDataChanged$.next({ metridId: id })
        return resp
      })
    )
  }

  public revertChangeEvent(ce: ChangeEvent) {
    return this.emmaHttp.revertChangeEvent(ce.id).pipe(
      tap((resp) => {
        this._rawDataChanged$.next({ metridId: ce.metricId })
        return resp
      })
    )
  }

  public updateUserDashboardsAccess(userId: string, dashboardIds: string[]) {
    return this.wrapSuccessWithDashboardsRefresh(this.emmaHttp.updateUserDashboardsAccess(userId, dashboardIds))
  }
}
