import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import {
  AcceptableMeasurementTypes,
  AggregatedMetric,
  AggregationMethods,
  AlertRule,
  AlertRulesResponse,
  AvailableDMSDevicesResponse,
  BaseUnitID,
  BaseUnitTypeID,
  BaseUnitsResponse,
  Calculation,
  ChangeLogRepsonse,
  ChartType,
  ComparisonType,
  CostsResponse,
  CostsTypes,
  CreateDeviceMapping,
  Dashboard,
  DashboardReport,
  DataExportJob,
  DataExportRequest,
  DataQueryParams,
  DeviceMappingsResponse,
  EmmaAlertMessage,
  EmmaAlertMessagesOptions,
  EmmaAlertMessagesResponse,
  FeatureSetAndLimitsResponse,
  Formula,
  FormulaCreationResponse,
  FormulaNodeTypes,
  FormulaOperators,
  FormulaValidationResponse,
  ImportCsvResponse,
  MeteringPoint,
  Metric,
  MetricValueUpdateResponse,
  QueryDataCSVResponse,
  QueryDataRawResponse,
  QueryDataResponseAggregated,
  QueryDataResponseRaw,
  QueryMetaDataResponseRaw,
  QueryableTypedID,
  Rate,
  RecurringReport,
  RegressionModel,
  ReplaceDeviceMapping,
  ReportGeneration,
  ReportGenerationStatus,
  SankeyDataResponse,
  StatisticsResponse,
  TypedQueryableID,
  Unit,
  UnitPrefix,
  UnitTypeDefaults,
  UnitTypeDefaultsRepsonse,
  UnitTypesResponse,
  WageInfoRepsonse,
  WageTypeInfo,
  Widget,
  WindowPeriods,
} from '@emma/type-definitions/types'
import { HttpBaseService } from '@services/http-base.service'
import { SessionData } from '@services/proficloud.interfaces'
import { ProficloudService } from '@services/proficloud.service'
import { format } from 'date-fns'
import { EventSourcePolyfill } from 'event-source-polyfill'
import { Observable, map } from 'rxjs'
import { AppService } from 'src/app/app.service'

@Injectable({
  providedIn: 'root',
})
export class EmmaHttpService extends HttpBaseService {
  private emmaEventSource: EventSourcePolyfill
  private reportCreationEventSource: EventSourcePolyfill

  constructor(
    protected override app: AppService,
    private httpClient: HttpClient,
    private proficloud: ProficloudService
  ) {
    super(app)
  }

  // Dashboards and Widgets
  public getDashboards() {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/dashboards/`
    return this.httpClient.get<Dashboard[]>(url)
  }

  public createDashboard(dashboard: Dashboard) {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/dashboards/`
    return this.httpClient.post<Dashboard>(url, dashboard)
  }

  public updateDashboard(id: string, params: Partial<Dashboard>) {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/dashboards/${id}/`
    return this.httpClient.patch<Dashboard>(url, params)
  }

  public deleteDashboard(id: string) {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/dashboards/${id}/`
    return this.httpClient.delete(url)
  }

  public updateUserDashboardsAccess(userId: string, dashboardIds: string[]) {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/users/${userId}/`
    return this.httpClient.put(url, { dashboardIds })
  }

  public createWidget(dashboardId: string, widget: Partial<Widget<ComparisonType, ChartType>>) {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/dashboards/${dashboardId}/widgets/`
    return this.httpClient.post<Widget<ComparisonType, ChartType>>(url, widget)
  }

  public updateWidget(dashboardId: string, widget: Partial<Widget<ComparisonType, ChartType>>) {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/dashboards/${dashboardId}/widgets/${widget.id}/`
    return this.httpClient.patch<Widget<ComparisonType, ChartType>>(url, widget)
  }

  public deleteWidget(dashboardId: string, widgetId: string) {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/dashboards/${dashboardId}/widgets/${widgetId}/`
    return this.httpClient.delete(url)
  }

  public renderWidgetPlot(dashboardId: string, widgetId: string) {
    const url = `${this.backendUrls.emmaUrl}/emma-report/api/v1/dashboards/${dashboardId}/widgets/${widgetId}/render-plot/`
    return this.httpClient.post(
      url,
      {
        timezone: this.proficloud.userTimezone,
      },
      {
        observe: 'response',
        responseType: 'arraybuffer',
      }
    )
  }

  public renderDashboardPlots(dashboardId: string) {
    const url = `${this.backendUrls.emmaUrl}/emma-report/api/v1/dashboards/${dashboardId}/render-plots/`
    return this.httpClient.post(
      url,
      {
        timezone: this.proficloud.userTimezone,
      },
      {
        observe: 'response',
        responseType: 'arraybuffer',
      }
    )
  }

  public getDashboardRecurringReports(dashboardId: string) {
    const url = `${this.backendUrls.emmaUrl}/emma-report/api/v1/dashboards/${dashboardId}/recurring-reports/`
    return this.httpClient.get<RecurringReport[]>(url)
  }

  public getDashboardReports(dashboardId: string) {
    const url = `${this.backendUrls.emmaUrl}/emma-report/api/v1/dashboards/${dashboardId}/reports/`
    return this.httpClient.get<DashboardReport[]>(url)
  }

  public getDashboardReportData(dashboardId: string, reportId: string) {
    const url = `${this.backendUrls.emmaUrl}/emma-report/api/v1/dashboards/${dashboardId}/reports/${reportId}/data/`
    return this.httpClient.get(url, {
      headers: {
        'Content-Type': 'application/pdf',
      },
      observe: 'response',
      responseType: 'blob',
    })
  }

  public createDashboardRecurringReport(dashboardId: string, report: Partial<RecurringReport>) {
    const url = `${this.backendUrls.emmaUrl}/emma-report/api/v1/dashboards/${dashboardId}/recurring-reports/`
    return this.httpClient.post(url, report)
  }

  public editDashboardRecurringReport(dashboardId: string, report: Partial<RecurringReport>) {
    const url = `${this.backendUrls.emmaUrl}/emma-report/api/v1/dashboards/${dashboardId}/recurring-reports/${report.id}/`
    return this.httpClient.patch(url, report)
  }

  public createDashboardManualReport(dashboardId: string, report: Partial<ReportGeneration>) {
    const url = `${this.backendUrls.emmaUrl}/emma-report/api/v1/dashboards/${dashboardId}/generation-tasks/`
    return this.httpClient.post<ReportGeneration>(url, report)
  }

  public deleteDashboardRecurringReport(dashboardId: string, reportId: string) {
    const url = `${this.backendUrls.emmaUrl}/emma-report/api/v1/dashboards/${dashboardId}/recurring-reports/${reportId}/`
    return this.httpClient.delete(url)
  }

  public deleteDashboardManualReport(dashboardId: string, reportId: string) {
    const url = `${this.backendUrls.emmaUrl}/emma-report/api/v1/dashboards/${dashboardId}/reports/${reportId}/`
    return this.httpClient.delete(url)
  }

  public pauseDashboardRecurringReport(dashboardId: string, reportId: string) {
    const url = `${this.backendUrls.emmaUrl}/emma-report/api/v1/dashboards/${dashboardId}/recurring-reports/${reportId}/`
    return this.httpClient.patch(url, { paused: true })
  }

  public resumeDashboardRecurringReport(dashboardId: string, reportId: string) {
    const url = `${this.backendUrls.emmaUrl}/emma-report/api/v1/dashboards/${dashboardId}/recurring-reports/${reportId}/`
    return this.httpClient.patch(url, { paused: false })
  }

  public getUnassignedMetrics() {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/metrics/`

    return this.httpClient.get<Metric[]>(url)
  }

  // Subscriptions
  public getFeatureSetAndLimits() {
    const url = `${this.backendUrls.emmaUrl}/emma-subscription/api/v1/feature-set-and-limits/`
    // const fake: FeatureSetAndLimitsResponse = {
    //   features: [],
    //   limits: {
    //     METRICS: 25,
    //     ALERTS: 5,
    //     WIDGETS: 4,
    //     LIVE_WIDGETS: 1,
    //     QUERY: 31536000, // seconds in a year
    //   },
    //   packageType: 'LEGACY',
    // }
    // return of(fake)
    return this.httpClient.get<FeatureSetAndLimitsResponse>(url)
  }

  // Metrics and Metering points
  public updateMetricValue(id: string, timestamp: string, fromValue: string | null, toValue: string | null) {
    const params: { timestamp: string; fromValue: string | null; toValue: string | null } = {
      timestamp: timestamp,
      fromValue,
      toValue,
    }

    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/metrics/${id}/values/`

    return this.httpClient.patch<MetricValueUpdateResponse>(url, params, {
      observe: 'response',
    })
  }

  public revertChangeEvent(id: string) {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/change-events/${id}/revert/`
    return this.httpClient.post<null>(url, {})
  }

  public getMeteringPointTree() {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/metering-points/`

    return this.httpClient.get<MeteringPoint[]>(url)
  }

  public createMeteringPoint(toCreate: MeteringPoint) {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/metering-points/`
    return this.httpClient.post<MeteringPoint>(url, toCreate)
  }

  public updateMeteringPoint(id: string, params: Partial<MeteringPoint>) {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/metering-points/${id}/`
    return this.httpClient.patch(url, params)
  }

  public deleteMeteringPoint(id: string) {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/metering-points/${id}/`
    return this.httpClient.delete(url)
  }

  public deleteMetric(id: string) {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/metrics/${id}/`
    return this.httpClient.delete(url)
  }

  public editMetric(id: string, params: Partial<Metric>) {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/metrics/${id}/`
    return this.httpClient.patch(url, params)
  }

  public assignMetricToMeteringPoint(metricId: string, meteringPointId: string | null) {
    // Note: To unassign a metric from a metering point and put it back into the inbox meteringPointId needs to be null not undefined
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/metrics/${metricId}/`
    return this.httpClient.patch(url, { meteringPointId })
  }

  public editAggregatedMetric(id: string, params: Partial<AggregatedMetric>) {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/aggregated-metrics/${id}/`
    return this.httpClient.patch(url, params)
  }

  public getBaseUnitDefaults() {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/units/unit-type-defaults/`

    return this.httpClient.get<UnitTypeDefaultsRepsonse[]>(url).pipe(
      map((d) => {
        const m = new Map<BaseUnitTypeID, UnitTypeDefaults>()
        d.forEach((x) => {
          m.set(x.unitType, x.defaults)
        })
        return m
      })
    )
  }

  public getWageTypeInfo() {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/units/wage-type-infos/`

    return this.httpClient.get<WageInfoRepsonse[]>(url).pipe(
      map((d) => {
        const m = new Map<Exclude<AcceptableMeasurementTypes, null>, WageTypeInfo>()
        d.forEach((x) => {
          m.set(x.wageType, x.info)
        })
        return m
      })
    )
  }

  public getUnitTypes() {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/units/unit-types/`

    return this.httpClient.get<UnitTypesResponse>(url)
  }

  public getBaseUnits() {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/units/base-units/`

    return this.httpClient.get<BaseUnitsResponse>(url)
  }

  public getCalculations() {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/calculations/`

    return this.httpClient.get<Calculation[]>(url)
  }

  public deleteCalculation(id: string) {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/calculations/${id}/`

    return this.httpClient.delete(url)
  }

  public updateCalculation(id: string, params: { appearance: { colour?: string } }) {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/calculations/${id}/`

    return this.httpClient.patch(url, params)
  }

  public createSumCalculation(params: { name: string; metricIds: string[]; meteringPointIds: TypedQueryableID[] }) {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/calculations/sum/`

    return this.httpClient.post(url, params)
  }

  public editSumCalculation(id: string, params: { name: string; metricIds: string[]; meteringPointIds: TypedQueryableID[] }) {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/calculations/sum/${id}/`

    return this.httpClient.patch(url, params)
  }

  public createAverageCalculation(params: { name: string; metricIds: string[]; meteringPointIds: TypedQueryableID[] }) {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/calculations/avg/`

    return this.httpClient.post(url, params)
  }

  public editAverageCalculation(id: string, params: { name: string; metricIds: string[]; meteringPointIds: TypedQueryableID[] }) {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/calculations/avg/${id}/`

    return this.httpClient.patch(url, params)
  }

  public createFormulaCalculation(
    name: string,
    nodes: {
      type: string
      value: string | { prefix: UnitPrefix; baseUnit: string } | { id: string; unitType: BaseUnitTypeID; measurementType: AcceptableMeasurementTypes }
    }[]
  ) {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/calculations/formula/`

    return this.httpClient.post<FormulaCreationResponse>(url, { name, nodes })
  }

  public updateFormulaCalculation(
    id: string,
    name: string,
    nodes: {
      type: string
      value: string | { prefix: UnitPrefix; baseUnit: string } | { id: string; unitType: BaseUnitTypeID; measurementType: AcceptableMeasurementTypes }
    }[]
  ) {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/calculations/formula/${id}/`

    return this.httpClient.patch<FormulaCreationResponse>(url, { name, nodes })
  }

  public validateFormula(nodes: { type: FormulaNodeTypes; value: any }[]) {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/calculations/validate-formula/`

    return this.httpClient.post<FormulaValidationResponse>(url, { nodes })
  }

  public uploadCSVMetrics(filesData: Record<string, string>[], meteringPointId?: string) {
    const url = `${this.backendUrls.emmaImportCsvUrl}/upload-csv-metrics`
    const formData = new FormData()
    for (var file of filesData) {
      formData.append(file.filename, new Blob([file.data]), file.filename)
    }

    const metaData = filesData.map((fd, i) => {
      return {
        filename: fd.filename,
        measurementTypeId: fd.measurementTypeID,
        unitName: fd.unitID,
        unitType: fd.unitType,
        prefix: fd.prefix,
        metricId: fd.metricId,
        meteringPointId,
      }
    })

    formData.append('metaData', JSON.stringify(metaData))
    return this.httpClient.post<ImportCsvResponse>(url, formData, {
      headers: new HttpHeaders(this.proficloud.getAuthHeader()),
      observe: 'response',
    })
  }

  public acceptCSVOverwrite(taskId: string) {
    const url = `${this.backendUrls.emmaImportCsvUrl}/upload-csv-metrics/${taskId}/deletion-acknowledged/`
    return this.httpClient.patch(url, null, {
      headers: new HttpHeaders(this.proficloud.getAuthHeader()),
      observe: 'response',
    })
  }

  public getAvailbleDMSDevices() {
    const url = `${this.backendUrls.emmaUrl}/emma-device-mapping/api/v1/dms-devices/`

    return this.httpClient.get<AvailableDMSDevicesResponse>(url)
  }

  public getDeviceMappings() {
    const url = `${this.backendUrls.emmaUrl}/emma-device-mapping/api/v1/device-mappings/`

    return this.httpClient.get<DeviceMappingsResponse>(url)
  }

  public createDMSDeviceMapping(mappings: CreateDeviceMapping[]) {
    const url = `${this.backendUrls.emmaUrl}/emma-device-mapping/api/v1/device-mappings/`
    const toCreate = {
      data: mappings,
    }
    return this.httpClient.post(url, toCreate)
  }

  // Costs/Rates
  public replaceDMSDeviceMapping(mapping: ReplaceDeviceMapping) {
    const url = `${this.backendUrls.emmaUrl}/emma-device-mapping/api/v1/device-mappings/`
    return this.httpClient.patch(url, mapping)
  }

  public createRate(costId: string, newRate: Rate) {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/costs/${costId}/rates/`

    return this.httpClient.post(url, newRate)
  }

  public updateRate(costId: string, rate: Rate) {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/costs/${costId}/rates/${rate.id}/`

    return this.httpClient.patch(url, rate)
  }

  public deleteRate(costId: string, rate: Rate) {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/costs/${costId}/rates/${rate.id}/`

    return this.httpClient.delete(url)
  }

  public getCosts() {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/costs/`

    return this.httpClient.get<CostsResponse>(url)
  }

  public updateCost(costId: string, price: number) {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/costs/${costId}/`

    return this.httpClient.patch(url, { price })
  }

  public updateCostUnit(costId: string, unit: Unit, type: 'cost' | 'consumption') {
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/costs/${costId}/`
    const params =
      type === 'consumption'
        ? {
            consumptionUnit: unit,
          }
        : {
            costUnit: unit,
          }
    return this.httpClient.patch(url, params)
  }

  // Alerting

  public getAlertRules() {
    const url = `${this.backendUrls.emmaNotificationUrl}/api/v1/alert-rules`
    return this.httpClient.get<AlertRulesResponse>(url)
  }

  public createAlertRule(newAlertRule: AlertRule) {
    const url = `${this.backendUrls.emmaNotificationUrl}/api/v1/alert-rules`

    return this.httpClient.post<AlertRule>(url, newAlertRule)
  }

  public updateAlertRule(id: string, params: Partial<AlertRule>) {
    const url = `${this.backendUrls.emmaNotificationUrl}/api/v1/alert-rules/${id}`

    return this.httpClient.patch<AlertRule>(url, params)
  }

  public deleteAlertRule(id: string) {
    const url = `${this.backendUrls.emmaNotificationUrl}/api/v1/alert-rules/${id}`

    return this.httpClient.delete(url)
  }

  public dismissAllEmmaNotifications() {
    const url = `${this.backendUrls.emmaNotificationUrl}/api/v1/alert-messages/acknowledge-all-messages`
    return this.httpClient.patch(url, {})
  }

  // option parm lets, what alert messages need to be fetched
  // option : all,acknowledged,unacknowledged
  //    all --> return all alertmsg
  //    acknowledged --> return acknowledged alertmsg
  //    unacknowledged --> return un-aknowledged msg
  public getEmmaAlertMessages(options: EmmaAlertMessagesOptions = { status: 'all', page: 1, limit: 25 }) {
    var url = `${this.backendUrls.emmaNotificationUrl}/api/v1/alert-messages?`

    if (options.status === 'acknowledged') {
      url += 'acknowledged=false'
    } else if (options.status === 'unacknowledged') {
      url += 'acknowledged=false'
    }

    if (options.page) {
      url += `&page=${options.page}`
    }

    if (options.limit) {
      url += `&limit=${options.limit}`
    }

    return this.proficloud.http.get<EmmaAlertMessagesResponse>(url)
  }

  public getEmmaAlertMessage(id: string) {
    var url = `${this.backendUrls.emmaNotificationUrl}/api/v1/alert-messages/${id}`

    return this.proficloud.http.get<EmmaAlertMessage>(url)
  }

  public deleteOrphanAlertMessages() {
    var url = `${this.backendUrls.emmaNotificationUrl}/api/v1/alert-messages/orphan-messages`

    return this.proficloud.http.delete(url)
  }

  public getAlertRuleMessages(parentID: string, options: EmmaAlertMessagesOptions = { status: 'all', page: 1, limit: 25 }) {
    var url = `${this.backendUrls.emmaNotificationUrl}/api/v1/alert-rules/${parentID}/alert-messages?`

    if (options.status === 'acknowledged') {
      url += 'acknowledged=false'
    } else if (options.status === 'unacknowledged') {
      url += 'acknowledged=false'
    }

    if (options.page) {
      url += `&page=${options.page}`
    }

    if (options.limit) {
      url += `&limit=${options.limit}`
    }

    return this.proficloud.http.get<EmmaAlertMessagesResponse>(url)
  }

  // SSE Streams

  public observeEmmaAlerts() {
    const url = `${this.backendUrls.emmaNotificationUrl}/api/v1/sse/alert-messages`
    return new Observable<EmmaAlertMessage>((obs) => {
      this.emmaEventSource = new EventSourcePolyfill(url, {
        headers: {
          Authorization: `Bearer ${(this.proficloud.keycloakData.session as SessionData).access_token}`,
        },
      })

      this.emmaEventSource.addEventListener('message', (evt) => {
        const data: EmmaAlertMessage = JSON.parse(evt.data)
        if (Object.keys(data).length > 0) {
          // console.log(evt)
          obs.next(data)
        }
      })
      // return () => es.close()
    })
  }

  public observeReportCreation(dashboardId: string, taskId: string) {
    const url = `${this.backendUrls.emmaUrl}/emma-report/api/v1/dashboards/${dashboardId}/generation-tasks/${taskId}/status-stream/`
    return new Observable((obs) => {
      this.reportCreationEventSource = new EventSourcePolyfill(url, {
        headers: {
          Authorization: `Bearer ${(this.proficloud.keycloakData.session as SessionData).access_token}`,
        },
      })

      this.reportCreationEventSource.addEventListener('message', (evt) => {
        const data: { data: ReportGenerationStatus } = JSON.parse(evt.data)
        if (Object.keys(data).length > 0) {
          // console.log(evt)
          obs.next(data)
        }
      })
      // return () => es.close()
    })
  }

  public stopReportCreationObservation() {
    this.reportCreationEventSource.close()
  }

  // Query data methods

  private queryablesToFormulas(
    queryables: QueryableTypedID[],
    options?: {
      unitType?: BaseUnitTypeID | 'kpi'
      costType?: CostsTypes | null
    }
  ): Formula {
    const metricIdsdata: Formula = queryables.map((q) => {
      // Note: Aggregated metrics actually call their parent metering point with a given measurement type
      if (q.type === 'metering_point' || q.type === 'aggregated_metric') {
        return [
          {
            type: 'metering_point',
            value: {
              id: q.id,
              // Note: The unit type will never be 'kpi' for a metering point because they don't belong to metering points so this is type safe
              unitType: q.unitType || options?.unitType || 'ENERGY',
              measurementType: q.measurementType,
            },
            costConversion: options?.costType,
          },
        ]
      }

      if (q.type === 'calculation' || q.type === 'metric') {
        return [
          {
            type: q.type,
            value: q.id,
            costConversion: options?.costType,
          },
        ]
      }

      // Just so the compiler doesn't complain but this shouldn't be possible
      return []
    })
    return metricIdsdata
  }

  public constructQueryDataParams(
    startDates: Date[],
    endDates: Date[],
    queryables: QueryableTypedID[],
    groupBy?: WindowPeriods,
    options?: {
      unit?: BaseUnitID
      unitType?: BaseUnitTypeID | 'kpi'
      prefix?: UnitPrefix
      aggregation?: AggregationMethods
      costType?: CostsTypes | null
    }
  ) {
    const startDateStrings = startDates.map((d) => format(d, 'yyyy-MM-dd') + 'T' + format(d, 'HH:mm:ssXXX'))
    const endDateStrings = endDates.map((d) => format(d, 'yyyy-MM-dd') + 'T' + format(d, 'HH:mm:ssXXX'))
    const metricIdsdata: Formula = this.queryablesToFormulas(queryables, options)
    const params: DataQueryParams = {
      dataRequest: {
        start: startDateStrings,
        end: endDateStrings,
        window: groupBy || '1h', // Note: if we're aggregating data then the window doesn't matter but it is required by the backend
        timezone: this.proficloud.userTimezone,
      },
      formula: metricIdsdata,
      aggregation: {
        function: options?.aggregation,
      },
    }

    // Note: Defaults will be returned if we don't specify any units
    // both unitType and unit have to be defined, otherwise the outputUnit cannot be defined
    if (options?.unitType && options?.unitType !== 'kpi' && options?.unit) {
      let typeParams: {
        [key in BaseUnitTypeID]?: {
          baseUnit: {
            unit: BaseUnitID
            unitType: BaseUnitTypeID
          }
          prefix: UnitPrefix
        }
      } = {}

      typeParams[options.unitType as BaseUnitTypeID] = {
        baseUnit: {
          unit: options.unit,
          unitType: options.unitType as BaseUnitTypeID, // Deal with KPI
        },
        prefix: options.prefix || '_',
      }

      params.outputUnits = typeParams
    }

    return params
  }

  public queryWidgetData(
    startDates: Date[],
    endDates: Date[],
    queryables: QueryableTypedID[],
    groupBy: WindowPeriods,
    options?: {
      unit?: BaseUnitID
      unitType?: BaseUnitTypeID | 'kpi'
      prefix?: UnitPrefix
      aggregation?: AggregationMethods
      costType?: CostsTypes | null
    }
  ) {
    const params = this.constructQueryDataParams(startDates, endDates, queryables, groupBy, options)
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/query/`

    return this.httpClient.post<QueryDataResponseRaw>(url, params, {
      observe: 'response',
    })
  }

  public queryWidgetForecastData(
    startDates: Date[],
    endDates: Date[],
    queryables: QueryableTypedID[],
    groupBy: WindowPeriods,
    options?: {
      unit?: BaseUnitID
      unitType?: BaseUnitTypeID | 'kpi'
      prefix?: UnitPrefix
      aggregation?: AggregationMethods
      costType?: CostsTypes | null
    }
  ) {
    const params = this.constructQueryDataParams(startDates, endDates, queryables, groupBy, options)
    const url = `${this.backendUrls.emmaUrl}/emma-prediction/api/v1/predict/`

    return this.httpClient.post<QueryDataResponseRaw>(url, params, {
      observe: 'response',
    })
  }

  public checkForecastModels(queryables: QueryableTypedID[]) {
    const params: Formula = this.queryablesToFormulas(queryables, {})
    const url = `${this.backendUrls.emmaUrl}/emma-prediction/api/v1/models/formula-models/`

    return this.httpClient.post<(RegressionModel | null)[]>(url, params, {
      observe: 'response',
    })
  }

  public trainForecastModel(queryable: QueryableTypedID) {
    let params: any
    // TODO: remove fixed values once API is fixed
    if (queryable.type == 'metering_point') {
      params = {
        modelName: 'TODO',
        meteringPointId: queryable.id,
        unitType: queryable.unitType,
        unit: 'WATT_HOUR',
        prefix: 'KILO',
        measurementType: queryable.measurementType,
        trainingDays: 100,
      }
    } else if (queryable.type == 'metric') {
      params = {
        modelName: 'TODO',
        metricId: queryable.id,
        trainingDays: 100,
        unit: 'WATT_HOUR',
        unitType: 'ENERGY',
        prefix: 'KILO',
      }
    } else {
      throw new Error('not supported for prediction')
    }
    const url = `${this.backendUrls.emmaUrl}/emma-prediction/api/v1/training-tasks/create-models/`

    return this.httpClient.post<QueryDataResponseRaw>(url, params, {
      observe: 'response',
    })
  }

  public queryWidgetMetaData(
    startDates: Date[],
    endDates: Date[],
    queryables: QueryableTypedID[],
    options?: {
      unit?: BaseUnitID
      unitType?: BaseUnitTypeID | 'kpi'
      prefix?: UnitPrefix
      aggregation?: AggregationMethods
      costType?: CostsTypes
    }
  ) {
    const params = this.constructQueryDataParams(startDates, endDates, queryables, undefined, options)

    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/query/meta-data/`

    return this.httpClient.post<QueryMetaDataResponseRaw>(url, params, {
      observe: 'response',
    })
  }

  public queryWidgetDataAggregated(
    startDates: Date[],
    endDates: Date[],
    queryables: QueryableTypedID[],
    groupBy?: WindowPeriods,
    options?: {
      unit?: BaseUnitID
      unitType?: BaseUnitTypeID | 'kpi'
      prefix?: UnitPrefix
      aggregation?: AggregationMethods
      costType?: CostsTypes | null
    }
  ) {
    const params = this.constructQueryDataParams(startDates, endDates, queryables, groupBy, options)

    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/query/`
    return this.httpClient.post<QueryDataResponseAggregated>(url, params, {
      observe: 'response',
    })
  }

  // Note: This is clearly duplication but I think that's nicer than having to have on ORed response type
  public queryWidgetDataCSV(
    startDates: Date[],
    endDates: Date[],
    decimalSeparator: string,
    columnSeparator: string,
    queryables: QueryableTypedID[],
    groupBy: WindowPeriods,
    options?: {
      unit?: BaseUnitID
      unitType?: BaseUnitTypeID | 'kpi'
      prefix?: UnitPrefix
      aggregation?: AggregationMethods
      costType?: CostsTypes
    }
  ) {
    const params = this.constructQueryDataParams(startDates, endDates, queryables, groupBy, options)
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/query/?csv=true&columnSeparator=${columnSeparator}&decimalSeparator=${decimalSeparator}`

    return this.httpClient.post<QueryDataCSVResponse>(url, params, {
      observe: 'response',
    })
  }

  public queryWidgetSankeyData(
    startDates: Date[],
    endDates: Date[],
    queryables: QueryableTypedID[],
    groupBy: WindowPeriods,
    options?: {
      unit?: BaseUnitID
      unitType?: BaseUnitTypeID | 'kpi'
      prefix?: UnitPrefix
      costType?: CostsTypes | null
    }
  ) {
    const params = this.constructQueryDataParams(startDates, endDates, queryables, groupBy, options)
    const getFormula: any = params.formula[0][0].value
    const transformedData = {
      dataRequest: {
        start: params.dataRequest.start,
        end: params.dataRequest.end,
        window: params.dataRequest.window,
        timezone: params.dataRequest.timezone,
      },
      meteringPoint: {
        id: getFormula.id,
        unitType: getFormula.unitType,
        measurementType: getFormula.measurementType,
        phase: 0,
      },
      costConversion: options?.costType,
      outputUnits: {},
    }

    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/query/sankey`

    return this.httpClient.post<SankeyDataResponse>(url, transformedData, {
      observe: 'response',
    })
  }

  public querySummedData(startDates: Date[], endDates: Date[], queryables: QueryableTypedID[], groupBy: WindowPeriods) {
    const startDateStrings = startDates.map((d) => format(d, 'yyyy-MM-dd') + 'T' + format(d, 'HH:mm:ssXXX'))
    const endDateStrings = endDates.map((d) => format(d, 'yyyy-MM-dd') + 'T' + format(d, 'HH:mm:ssXXX'))
    const summedMetricsFormula: ({ type: 'metric' | 'calculation'; value: string } | { type: 'operator'; value: FormulaOperators })[] =
      // Note: No metering points for now
      []
    queryables.map((q, i) => {
      summedMetricsFormula.push({
        type: q.type as 'metric' | 'calculation',
        value: q.id,
      })
      if (i < queryables.length - 1) {
        summedMetricsFormula.push({
          type: 'operator',
          value: '+',
        })
      }
    })

    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/query/`

    const data: DataQueryParams = {
      dataRequest: {
        start: startDateStrings,
        end: endDateStrings,
        window: groupBy || '1h',
      },
      formula: [summedMetricsFormula],
      aggregation: {},
      // outputUnits: {}, // TODO: Make this default unit for the measurement type
    }

    return this.httpClient.post<QueryDataResponseRaw>(url, data, {
      observe: 'response',
    })
  }

  public requestDateEmailExport(
    emails: string[],
    decimalSeparator: string,
    columnSeparator: string,
    startDates: Date[],
    endDates: Date[],
    queryableIds: QueryableTypedID[],
    groupBy: WindowPeriods,
    options?: {
      unit?: BaseUnitID
      unitType?: BaseUnitTypeID | 'kpi'
      prefix?: UnitPrefix
      aggregation?: AggregationMethods
      costType?: CostsTypes
    }
  ) {
    const params = this.constructQueryDataParams(startDates, endDates, queryableIds, groupBy, options)
    const exportRequest: DataExportRequest = {
      receiverMail: emails,
      decimalSeparator: decimalSeparator,
      columnSeparator: columnSeparator,
      exportName: 'Data Export',
      queryRequest: params,
    }
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/export/`

    return this.httpClient.post<DataExportJob[]>(url, exportRequest, {
      observe: 'response',
    })
  }

  public queryWidgetStatistics(
    startDates: Date[],
    endDates: Date[],
    queryables: QueryableTypedID[],
    groupBy: WindowPeriods,
    unitType?: BaseUnitTypeID | 'kpi',
    options?: {
      costType?: CostsTypes
      unit?: BaseUnitID
      unitType?: BaseUnitTypeID
      prefix?: UnitPrefix
    }
  ) {
    const startDateStrings = startDates.map((d) => format(d, 'yyyy-MM-dd') + 'T' + format(d, 'HH:mm:ssXXX'))
    const endDateStrings = endDates.map((d) => format(d, 'yyyy-MM-dd') + 'T' + format(d, 'HH:mm:ssXXX'))
    const metricIdsdata = queryables.map((q) => {
      if (q.type === 'metering_point') {
        return [
          {
            type: q.type,
            value: {
              id: q.id,
              unitType: unitType || 'ENERGY',
            },
            costConversion: options?.costType,
          },
        ]
      } else {
        return [
          {
            type: q.type,
            value: q.id,
            costConversion: options?.costType,
          },
        ]
      }
    })
    const url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/query/statistics/`

    let outputUnits: {
      [key in BaseUnitTypeID]?: {
        baseUnit: {
          unit: BaseUnitID
          unitType: BaseUnitTypeID
        }
        prefix: UnitPrefix
      }
    } = {}
    if (options?.unit && options?.unitType && options?.prefix) {
      outputUnits[options.unitType as BaseUnitTypeID] = {
        baseUnit: {
          unit: options.unit,
          unitType: options.unitType as BaseUnitTypeID, // Deal with KPI
        },
        prefix: options.prefix,
      }
    }

    const data = {
      dataRequest: {
        start: startDateStrings,
        end: endDateStrings,
        window: groupBy,
      },
      formula: metricIdsdata,
      aggregation: {
        // function: 'null',
      },
      outputUnits: outputUnits,
    }

    return this.httpClient.post<StatisticsResponse>(url, data, { observe: 'response' })
  }

  public queryRawData(metricIds: string[], startDate: Date, endDate: Date, options?: { page?: number; limit?: number; includeEmpty?: boolean }) {
    const idsString = metricIds.join(',')
    let url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/raw-data/?include_empty=${
      options?.includeEmpty ? 'true' : 'false'
    }&metric_ids=${encodeURIComponent(idsString)}&start_date=${encodeURIComponent(
      format(startDate, 'yyyy-MM-dd') + 'T' + format(startDate, 'HH:mm:ssXXX')
    )}&end_date=${encodeURIComponent(format(endDate, 'yyyy-MM-dd') + 'T' + format(endDate, 'HH:mm:ssXXX'))}`

    if (options?.limit && options?.limit >= 0) {
      url += `&limit=${options.limit}`
    }
    if (options?.page && options?.page >= 0) {
      url += `&page=${options.page + 1}`
    }
    return this.httpClient.get<QueryDataRawResponse>(url, {
      observe: 'response',
    })
  }

  public getChangeLogs(metricIds: string[], startDate: Date, endDate: Date, options?: { page?: number; limit?: number }) {
    const idsString = metricIds.join(',')
    let url = `${this.backendUrls.emmaUrl}/emma-tree/api/v1/change-events/?metric_ids=${encodeURIComponent(idsString)}&start_date=${encodeURIComponent(
      format(startDate, 'yyyy-MM-dd') + 'T' + format(startDate, 'HH:mm:ssXXX')
    )}&end_date=${encodeURIComponent(format(endDate, 'yyyy-MM-dd') + 'T' + format(endDate, 'HH:mm:ssXXX'))}`

    if (options?.limit && options?.limit >= 0) {
      url += `&limit=${options.limit}`
    }
    if (options?.page && options?.page >= 0) {
      url += `&page=${options.page + 1}`
    }
    return this.httpClient
      .get<ChangeLogRepsonse>(url, {
        observe: 'response',
      })
      .pipe(
        map((resp) => {
          if (!resp.body) {
            console.warn('response body null')
            return
          }
          resp.body.data.forEach((log) => {
            // convert to Dates to conform interface
            log.createdAt = new Date(log.createdAt)
            log.timestamp = log.timestamp !== null ? new Date(log.timestamp) : null
            log.replaceStart = log.replaceStart !== null ? new Date(log.replaceStart) : null
            log.replaceEnd = log.replaceEnd !== null ? new Date(log.replaceEnd) : null
          })
          return resp
        })
      )
  }
}
